var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-text-field',{attrs:{"label":"Introducir dirección de correo electrónico","error-messages":_vm.submittedWithErrors && _vm.v$.userEmail.$invalid
          ? 'El email es inválido'
          : null,"outlined":"","dense":"","required":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Introducir nombre completo","error-messages":_vm.submittedWithErrors && _vm.v$.fullName.$invalid
          ? 'El nombre no puede estar vacío'
          : null,"outlined":"","dense":"","required":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"fullName"}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Introducir contraseña","error-messages":_vm.submittedWithErrors && _vm.v$.password.$invalid
          ? 'La contraseña debe de tener al menos 6 caracteres'
          : null,"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":"","required":""},on:{"click:append":_vm.toggleShowPassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}})],1),_c('div',[_c('v-text-field',{attrs:{"label":"Repetir contraseña","error-messages":_vm.submittedWithErrors && _vm.v$.repeatPassword.$invalid
          ? 'Las contraseñas no coinciden'
          : null,"type":_vm.showRepeatPassword ? 'text' : 'password',"append-icon":_vm.showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":"","required":""},on:{"click:append":_vm.toggleShowRepeatPassword},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"repeatPassword"}})],1),_c('div',{staticClass:"mb-2"},[_c('v-checkbox',{attrs:{"error-messages":_vm.submittedWithErrors && _vm.v$.acceptedTerms.$invalid
          ? 'Debe aceptar para poder crear la cuenta'
          : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Al usar Goveo estas aceptando las "),_c('a',{attrs:{"target":"_blank","href":"https://goveo.app/conditions-user"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Condiciones de Servicio ")]),_vm._v(" y "),_c('a',{attrs:{"target":"_blank","href":"https://goveo.app/privacy-app"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Política de Privacidad ")])])]},proxy:true}]),model:{value:(_vm.acceptedTerms),callback:function ($$v) {_vm.acceptedTerms=$$v},expression:"acceptedTerms"}})],1),_c('div',[_c('v-btn',{staticClass:"submit-btn",attrs:{"block":"","loading":_vm.submitting,"color":"primary"},on:{"click":_vm.signUp}},[_vm._v(" Crear cuenta ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }