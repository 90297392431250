<template>
    <div>
        <div class="text-center">
            <v-dialog
                    v-model="termsDialog"
                    persistent
                    width="500"
            >
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        Condiciones de registro
                    </v-card-title>

                    <v-card-text>
                        Al usar Goveo estas aceptando las
                        <a
                                target="_blank"
                                href="https://goveo.app/conditions-user"
                                @click.stop
                        >
                            Condiciones de Servicio
                        </a>
                        y
                        <a target="_blank" href="https://goveo.app/privacy-app" @click.stop>
                            Política de Privacidad
                        </a>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="declined"
                        >
                            No acepto
                        </v-btn>
                        <v-btn
                                color="primary"
                                depressed
                                @click="accepted"
                        >
                            Acepto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      termsDialog: Boolean
    },
    setup() {
      const declined = function() {
        this.$emit('declined');
      };

      const accepted = function() {
        this.$emit('accepted');
      };

      return {
        declined,
        accepted
      };
    }
  };
</script>

<style type="scss">
    h2 {
        margin: 0;
    }
    .success {
        border: solid 1px green;
    }
    .error {
        border: solid 1px red;
    }

    .submit-btn {
        letter-spacing: initial !important;
        text-transform: initial !important;
        font-weight: 600 !important;
        font-size: 15px !important;
    }
</style>
