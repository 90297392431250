import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

let envConfig;

if (window.location.host === "id.goveo.app") {
  envConfig = require("./prod.js").default;
} else {
  envConfig = require("./dev.js").default;
}

firebase.initializeApp(envConfig.firebaseConfig);
const auth = firebase.auth();
const usersRefFirestore = firebase.firestore().collection("users");
const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");

const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope("email");

const appleProvider = new firebase.auth.OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
appleProvider.setCustomParameters({
  locale: "es"
});

const Timestamp = firebase.firestore.Timestamp;

// export utils/refs
export {
  auth,
  usersRefFirestore,
  googleProvider,
  facebookProvider,
  appleProvider,
  Timestamp
};
