
export function useContinue(props) {
  const token = props.token || null;

  const continueToUrl = () => {
    let hostQueryParams = new URLSearchParams(window.location.search);
    const continueUrl = hostQueryParams.get('continue');

    if (continueUrl) {
      let continueUrlURL = new URL(continueUrl);

      let queryParams = new URLSearchParams(continueUrlURL.search);
      queryParams.set('token', token);

      window.location.href = `${continueUrlURL.protocol}//${continueUrlURL.host}${continueUrlURL.pathname}?${queryParams.toString()}`;
    }
  };

  return {
    continueToUrl
  };
}
