import {
  auth,
  googleProvider,
  Timestamp,
  usersRefFirestore
} from "../firebase/provider";
import { CustomToken } from "../firebase/customToken";
import { ref } from "@vue/composition-api";

const ERRORS = {
  "auth/unauthorized-domain":
    "Este dominio no está autorizado para hacer uso de este tipo de inicio de sesión",
  "auth/user-disabled":
    "Su cuenta ha sido suspendida por alguna razón. Contacte con soporte@goveo.app.",
  "auth/account-exists-with-different-credential":
    "Este email ya se ha registrado con otra forma de inicio de sesión."
};

export const IGNORE_ERRORS = ["auth/popup-closed-by-user", "invalid-form"];

export function useLoginByGoogle() {
  const submitting = ref(false);

  const submit = async () => {
    submitting.value = true;

    try {
      const authenticatedUser = await auth.signInWithPopup(googleProvider);
      const idToken = await authenticatedUser.user.getIdToken();
      const customToken = await new CustomToken().create(idToken);
      const goveoUser = await usersRefFirestore
        .doc(authenticatedUser.user.uid)
        .get();

      if (!goveoUser.exists) {
        await usersRefFirestore.doc(authenticatedUser.user.uid).set({
          name: authenticatedUser.user.displayName,
          email: authenticatedUser.user.email,
          profileImage: authenticatedUser.user.photoURL,
          createdAt: new Timestamp.fromDate(new Date())
        });
      }

      submitting.value = false;

      return {
        data: {
          token: customToken
        }
      };
    } catch (e) {
      submitting.value = false;
      return {
        error: {
          code: e.code,
          message: ERRORS[e.code] ? ERRORS[e.code] : e.code
        }
      };
    }
  };

  return {
    submit
  };
}
