import { ref } from "@vue/composition-api";
import { auth } from "../firebase/provider";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { CustomToken } from "../firebase/customToken";

const ERRORS = {
  "auth/invalid-email": "El email es inválido",
  "auth/user-not-found": "El email no está registrado en Goveo",
  "invalid-form": "Los datos son inválidos."
};

export const IGNORE_ERRORS = ["invalid-form"];

export function useResetPassword(props) {
  const userEmail = ref(props.userEmail || "");
  const submittedWithErrors = ref(false);
  const submitting = ref(false);

  const v$ = useVuelidate(
    {
      userEmail: {
        required,
        email,
        $autoDirty: true
      }
    },
    { userEmail },
    "resetPassword"
  );

  const submit = async () => {
    v$.value.$touch();
    submitting.value = true;

    if (v$.value.$invalid) {
      submittedWithErrors.value = true;
      submitting.value = false;

      return {
        error: {
          code: "invalid-form",
          message: ERRORS["invalid-form"]
        }
      };
    }

    try {
      await auth.sendPasswordResetEmail(userEmail.value);
      clearForm();
    } catch (e) {
      submitting.value = false;
      return {
        error: {
          code: e.code,
          message: ERRORS[e.code] ? ERRORS[e.code] : e.message
        }
      };
    }
  };

  const clearForm = () => {
    userEmail.value = "";
    submittedWithErrors.value = false;
    submitting.value = false;
    v$.value.$reset();
  };

  return {
    userEmail,
    submittedWithErrors,
    submitting,
    v$,
    submit
  };
}
