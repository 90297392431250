export default {
  createCustomTokenUrl:
    "https://europe-west1-goveoapp-fd8b1.cloudfunctions.net/app/auth/createCustomToken",
  firebaseConfig: {
    apiKey: "AIzaSyA0u5ewNc65FXt1g3VBEZF8APD2ytnKd9I",
    authDomain: "goveoapp-fd8b1.firebaseapp.com",
    databaseURL: "https://goveoapp-fd8b1.firebaseio.com",
    projectId: "goveoapp-fd8b1",
    storageBucket: "goveoapp-fd8b1.appspot.com",
    messagingSenderId: "940550817024",
    appId: "1:940550817024:web:2c22c2fcfc8cd23fba5fe5",
    measurementId: "G-SNZBT6GR6R"
  }
};
