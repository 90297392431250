import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import SignUp from "../views/SignUp.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title:
        "Inicia sesión para continuar - Iniciar sesión con la cuenta de Goveo"
    }
  },
  {
    path: "/login/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title:
        "¿No puedes iniciar sesión? - Iniciar sesión con la cuenta de Goveo"
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: {
      title: "Registrarse - Iniciar sesión con la cuenta de Goveo"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (from.query.continue && !to.query.continue) {
    to.query.continue = from.query.continue;
    next({ path: to.path, query: to.query });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title;
  });
});

export default router;
