<template>
  <div>
    <p class="page-title text-center">¿No puedes iniciar sesión?</p>
    <div>
      <v-text-field
        label="Enviaremos un enlace de recuperación a"
        placeholder="Introducir correo"
        v-model.trim="userEmail"
        :error-messages="
          submittedWithErrors && v$.userEmail.$invalid
            ? 'El email es inválido'
            : null
        "
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-btn
        block
        :loading="submitting"
        color="primary"
        @click="resetPassword"
        class="submit-btn"
      >
        Enviar enlace de recuperación
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useResetPassword, IGNORE_ERRORS } from "../use/useResetPassword";

export default {
  setup(props, context) {
    const {
      userEmail,
      submittedWithErrors,
      submitting,
      v$,
      submit
    } = useResetPassword({});

    const resetPassword = async function() {
      const result = await submit();

      if (result && result.error) {
        if (!IGNORE_ERRORS.includes(result.error.code)) {
          context.emit("onError", result.error.message);
        }
      } else {
        context.emit("onSuccess", "Hemos enviado las instrucciones de Recuperación de Contraseña al email que nos has facilitado.");
        await this.$router.push("/login");
      }
    };

    return {
      userEmail,
      submittedWithErrors,
      submitting,
      v$,
      resetPassword
    };
  }
};
</script>

<style type="scss">
.submit-btn {
  letter-spacing: initial !important;
  text-transform: initial !important;
  font-weight: 600 !important;
}

.page-title {
  font-size: 16px;
  font-weight: 600;
}
</style>
