<template>
  <div>
    <v-btn
      block
      :loading="submitting"
      color="white"
      @click="clicked"
      class="submit-btn"
    >
      <img
        style="height: 18px; margin-right: 8px; width: 18px;"
        src="https://res.cloudinary.com/goveo/image/upload/v1608048490/f_logo_RGB-Blue_58_d24l6y.png"
      />
      Continuar con Facebook
    </v-btn>
      <TermsDialog
              :terms-dialog="termsDialog"
              @declined="declinedTerms"
              @accepted="acceptedTerms"
      ></TermsDialog>
  </div>
</template>

<script>
import { useLoginByFacebook, IGNORE_ERRORS } from "../use/useLoginByFacebook";
import { useContinue } from "../use/useContinue";
import { useTermsDialog } from "../use/useTermsDialog";
import TermsDialog from "./TermsDialog";

export default {
  components: { TermsDialog },
  props: {
    signup: Boolean
  },
  setup(props, context) {
    const { submit, submitting } = useLoginByFacebook({});
    const { openTerms, closeTerms, termsDialog } = useTermsDialog({});

    const signInWithFacebook = async function() {
      const result = await submit();

      if (result.error) {
        if (!IGNORE_ERRORS.includes(result.error.code)) {
          context.emit('onError', result.error.message);
        }
      } else {
        const { continueToUrl } = useContinue({
          token: result.data.token
        });
        continueToUrl();
      }
    };

    const declinedTerms = function() {
      closeTerms();
    };

    const acceptedTerms = async function() {
      closeTerms();
      await signInWithFacebook();
    };

    const clicked = async function() {
      if (props.signup) {
        openTerms();
      } else {
        await signInWithFacebook();
      }
    }

    return {
      submitting,
      clicked,
      termsDialog,
      declinedTerms,
      acceptedTerms
    };
  }
};
</script>

<style type="scss">
h2 {
  margin: 0;
}
.success {
  border: solid 1px green;
}
.error {
  border: solid 1px red;
}

.submit-btn {
  letter-spacing: initial !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}
</style>
