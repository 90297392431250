import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCompositionAPI from "@vue/composition-api";

import vuetify from "./plugins/vuetify";
import "alertifyjs/build/alertify.min.js";
import Alertifyjs from "vue2-alertifyjs";

Vue.config.productionTip = false;

let envConfig;
let isProd;

if (
  window.location.host === "id.goveo.app" ||
  window.location.host === "goveoid.web.app"
) {
  isProd = true;
  envConfig = require("./firebase/prod.js").default;
} else {
  isProd = false;
  envConfig = require("./firebase/dev.js").default;
}

window.envConfig = envConfig;
window.isProd = isProd;

const alertifyOpts = {
  notifier: {
    delay: 4,
    position: "top-center",
    closeButton: true
  }
};

Vue.use(VueCompositionAPI);
Vue.use(Alertifyjs, alertifyOpts);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
