import { ref } from "@vue/composition-api";
import { auth, usersRefFirestore, Timestamp } from "../firebase/provider";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { CustomToken } from "../firebase/customToken";

const ERRORS = {
  "auth/email-already-in-use":
    "Esta cuenta ya esta siendo utilizada, intente iniciar sesión o contacte con un administrador a través de soporte@goveo.app",
  "auth/invalid-email": "El email es inválido.",
  "auth/weak-password":
    "La contraseña es insegura, asegurese de que tenga al menos 6 caracteres.",
  "invalid-form": "Los datos son inválidos."
};

export const IGNORE_ERRORS = ["invalid-form"];

export function useSignUpByEmail(redirectAfterValidation) {
  const userEmail = ref("");
  const fullName = ref("");
  const password = ref("");
  const repeatPassword = ref("");
  const acceptedTerms = ref(false);

  const showPassword = ref(false);
  const showRepeatPassword = ref(false);
  const submittedWithErrors = ref(false);
  const submitting = ref(false);

  const v$ = useVuelidate(
    {
      fullName: {
        required,
        $autoDirty: true
      },
      userEmail: {
        required,
        email,
        $autoDirty: true
      },
      password: {
        required,
        minLength: minLength(6),
        $autoDirty: true
      },
      repeatPassword: {
        required,
        sameAs: sameAs(password),
        $autoDirty: true
      },
      acceptedTerms: {
        accepted: val => val === true,
        $autoDirty: true
      }
    },
    { fullName, userEmail, password, repeatPassword, acceptedTerms },
    "signUpByEmail"
  );

  const submit = async () => {
    v$.value.$touch();
    submitting.value = true;

    if (v$.value.$invalid) {
      submittedWithErrors.value = true;
      submitting.value = false;

      return {
        error: {
          code: "invalid-form",
          message: ERRORS["invalid-form"]
        }
      };
    }

    try {
      const firebaseUser = await auth.createUserWithEmailAndPassword(
        userEmail.value,
        password.value
      );
      await firebaseUser.user.sendEmailVerification({
        url: redirectAfterValidation //`${window.location.protocol}//${window.location.hostname}/login`
      });

      const goveoUser = await usersRefFirestore
        .doc(firebaseUser.user.uid)
        .get();

      if (!goveoUser.exists) {
        await usersRefFirestore.doc(firebaseUser.user.uid).set({
          name: fullName.value,
          email: userEmail.value,
          createdAt: new Timestamp.fromDate(new Date())
        });
      }

      clearForm();
      return {
        user: {
          fullName: fullName.value,
          userEmail: userEmail.value
        }
      };
    } catch (e) {
      submitting.value = false;
      return {
        error: {
          code: e.code,
          message: ERRORS[e.code] ? ERRORS[e.code] : e.message
        }
      };
    }
  };

  const clearForm = () => {
    fullName.value = "";
    userEmail.value = "";
    password.value = "";
    repeatPassword.value = "";
    acceptedTerms.value = false;
    submittedWithErrors.value = false;
    submitting.value = false;
    v$.value.$reset();
  };

  const toggleShowPassword = () => {
    showPassword.value = !showPassword.value;
  };

  const toggleShowRepeatPassword = () => {
    showRepeatPassword.value = !showRepeatPassword.value;
  };

  return {
    userEmail,
    fullName,
    password,
    repeatPassword,
    acceptedTerms,
    showPassword,
    showRepeatPassword,
    submittedWithErrors,
    submitting,
    toggleShowPassword,
    toggleShowRepeatPassword,
    v$,
    submit
  };
}
