<template>
  <v-app class="app">
    <div class="d-flex flex-column justify-center  auth-container">
      <div class="d-flex flex-column align-center justify-center">
        <div>
          <img src="@/assets/logo.png" width="238" />
        </div>
        <br />
        <v-card class="page-card" max-width="400">
          <div class="d-flex flex-column">
            <p class="page-title text-center">Alta de Usuario</p>
            <LoginByGoogle
              :signup="true"
              @onError="onError"
            ></LoginByGoogle>
            <div class="mb-4"></div>
            <LoginByFacebook
              :signup="true"
              @onError="onError"
            ></LoginByFacebook>
            <p class="or-text mt-3 mb-3 text-center">O BIEN</p>
            <SignUpByEmail
              @onError="onError"
              @onSuccess="onSuccess"
            ></SignUpByEmail>
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <div class="auth-actions">
            <ul>
              <li>
                <router-link to="/login" class="no-decoration">
                  <span>¿Ya tienes una cuenta de Goveo? Iniciar sesión</span>
                </router-link>
              </li>
            </ul>
          </div>
        </v-card>
        <br />
        <PrivacyFooter></PrivacyFooter>
      </div>
    </div>
  </v-app>
</template>

<script>
import SignUpByEmail from "../components/SignUpByEmail.vue";
import LoginByGoogle from "../components/LoginByGoogle.vue";
import LoginByFacebook from "../components/LoginByFacebook.vue";
import PrivacyFooter from "../components/PrivacyFooter.vue";

export default {
  components: {
    SignUpByEmail,
    LoginByGoogle,
    LoginByFacebook,
    PrivacyFooter
  },
  setup() {
    const onError = function(error) {
      this.$notify(error, "error");
    };

    const onSuccess = function(message) {
      this.$notify(message, "success");
    };

    return {
      onError,
      onSuccess
    };
  }
};
</script>

<style type="scss">
.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.auth-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.auth-card-content {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}
</style>
