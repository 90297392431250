<template>
  <div>
    <div>
      <v-text-field
        label="Introducir dirección de correo electrónico"
        v-model.trim="userEmail"
        :error-messages="
          submittedWithErrors && v$.userEmail.$invalid
            ? 'El email es inválido'
            : null
        "
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Introducir nombre completo"
        v-model.trim="fullName"
        :error-messages="
          submittedWithErrors && v$.fullName.$invalid
            ? 'El nombre no puede estar vacío'
            : null
        "
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Introducir contraseña"
        v-model.trim="password"
        :error-messages="
          submittedWithErrors && v$.password.$invalid
            ? 'La contraseña debe de tener al menos 6 caracteres'
            : null
        "
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowPassword"
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Repetir contraseña"
        v-model.trim="repeatPassword"
        :error-messages="
          submittedWithErrors && v$.repeatPassword.$invalid
            ? 'Las contraseñas no coinciden'
            : null
        "
        :type="showRepeatPassword ? 'text' : 'password'"
        :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowRepeatPassword"
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div class="mb-2">
      <v-checkbox
        v-model="acceptedTerms"
        :error-messages="
          submittedWithErrors && v$.acceptedTerms.$invalid
            ? 'Debe aceptar para poder crear la cuenta'
            : null
        "
      >
        <template v-slot:label>
          <div>
            Al usar Goveo estas aceptando las
            <a
              target="_blank"
              href="https://goveo.app/conditions-user"
              @click.stop
            >
              Condiciones de Servicio
            </a>
            y
            <a target="_blank" href="https://goveo.app/privacy-app" @click.stop>
              Política de Privacidad
            </a>
          </div>
        </template>
      </v-checkbox>
    </div>
    <div>
      <v-btn
        block
        :loading="submitting"
        color="primary"
        @click="signUp"
        class="submit-btn"
      >
        Crear cuenta
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useSignUpByEmail, IGNORE_ERRORS } from "../use/useSignUpByEmail";

export default {
  setup(props, context) {
    let redirectAfterValidation = `${window.location.protocol}//${window.location.hostname}/login`;
    let hostQueryParams = new URLSearchParams(window.location.search);
    redirectAfterValidation = redirectAfterValidation + "/?" + hostQueryParams.toString();

    const {
      userEmail,
      fullName,
      password,
      repeatPassword,
      acceptedTerms,
      showPassword,
      showRepeatPassword,
      submittedWithErrors,
      submitting,
      toggleShowPassword,
      toggleShowRepeatPassword,
      v$,
      submit
    } = useSignUpByEmail(redirectAfterValidation);

    const signUp = async function() {
      const result = await submit();

      if (result.error) {
        if (!IGNORE_ERRORS.includes(result.error.code)) {
          context.emit('onError', result.error.message);
        }
      } else {
        context.emit('onSuccess', "Se ha creado la cuenta correctamente. Para terminar el registro debe validar su cuenta desde el email que le acabamos de enviar.");
      }
    };

    return {
      userEmail,
      fullName,
      password,
      repeatPassword,
      acceptedTerms,
      showPassword,
      showRepeatPassword,
      submittedWithErrors,
      submitting,
      toggleShowPassword,
      toggleShowRepeatPassword,
      v$,
      signUp
    };
  }
};
</script>

<style type="scss">
.submit-btn {
  letter-spacing: initial !important;
  text-transform: initial !important;
  font-weight: 600 !important;
}

.page-title {
  font-size: 16px;
  font-weight: 600;
}
</style>
