<template>
  <div>
    <div>
      <v-text-field
        label="Introducir correo"
        v-model.trim="userEmail"
        :error-messages="
          submittedWithErrors && v$.userEmail.$invalid
            ? 'El email es inválido'
            : null
        "
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Introducir contraseña"
        v-model.trim="password"
        :error-messages="
          submittedWithErrors && v$.password.$invalid
            ? 'La contraseña no puede estar vacía'
            : null
        "
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleShowPassword"
        outlined
        dense
        required
      ></v-text-field>
    </div>
    <div>
      <v-btn
        block
        :loading="submitting"
        color="primary"
        @click="signIn"
        class="submit-btn"
      >
        Iniciar sesión
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useLoginByEmail, IGNORE_ERRORS } from "../use/useLoginByEmail";
import { useContinue } from "../use/useContinue";

export default {
  setup(props, context) {
    const {
      userEmail,
      password,
      showPassword,
      submittedWithErrors,
      submitting,
      v$,
      submit,
      toggleShowPassword
    } = useLoginByEmail({});

    const signIn = async function() {
      const result = await submit();

      if (result.error) {
        if (!IGNORE_ERRORS.includes(result.error.code)) {
          context.emit('onError', result.error.message);
        }
      } else {
        const { continueToUrl } = useContinue({
          token: result.data.token
        });
        continueToUrl();
      }
    };

    return {
      userEmail,
      password,
      showPassword,
      submittedWithErrors,
      submitting,
      v$,
      signIn,
      toggleShowPassword
    };
  }
};
</script>

<style type="scss">
.submit-btn {
  letter-spacing: initial !important;
  text-transform: initial !important;
  font-weight: 600 !important;
}

.page-title {
  font-size: 16px;
  font-weight: 600;
}
</style>
