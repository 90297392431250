import axios from "axios";

export const CustomToken = function() {
  return {
    async create(idToken) {
      const response = await axios({
        url: window.envConfig.createCustomTokenUrl,
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      return response.data.data.customToken;
    }
  };
};
