import { ref } from "@vue/composition-api";
import { auth } from "../firebase/provider";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { CustomToken } from "../firebase/customToken";

const ERRORS = {
  "auth/invalid-email": "El email o contraseña son incorrectos.",
  "auth/user-disabled":
    "Su cuenta ha sido suspendida por alguna razón. Contacte con soporte@goveo.app.",
  "auth/user-not-found": "El email o contraseña son incorrectos.",
  "auth/wrong-password": "El email o contraseña son incorrectos.",
  "auth/account-exists-with-different-credential":
    "Este email ya se ha registrado con otra forma de inicio de sesión.",
  "invalid-form": "Los datos son inválidos."
};

export const IGNORE_ERRORS = ["invalid-form"];

export function useLoginByEmail(props) {
  const userEmail = ref(props.userEmail || "");
  const password = ref(props.password || "");
  const showPassword = ref(false);
  const submittedWithErrors = ref(false);
  const submitting = ref(false);

  const v$ = useVuelidate(
    {
      userEmail: {
        required,
        email,
        $autoDirty: true
      },
      password: {
        required,
        $autoDirty: true
      }
    },
    { userEmail, password },
    "loginByEmail"
  );

  const submit = async () => {
    v$.value.$touch();
    submitting.value = true;

    if (v$.value.$invalid) {
      submittedWithErrors.value = true;
      submitting.value = false;

      return {
        error: {
          code: "invalid-form",
          message: ERRORS["invalid-form"]
        }
      };
    }

    try {
      const authenticatedUser = await auth.signInWithEmailAndPassword(
        userEmail.value,
        password.value
      );
      const idToken = await authenticatedUser.user.getIdToken();
      const customToken = await new CustomToken().create(idToken);
      clearForm();
      return {
        data: {
          token: customToken
        }
      };
    } catch (e) {
      submitting.value = false;
      return {
        error: {
          code: e.code,
          message: ERRORS[e.code] ? ERRORS[e.code] : e.message
        }
      };
    }
  };

  const clearForm = () => {
    userEmail.value = "";
    password.value = "";
    submittedWithErrors.value = false;
    submitting.value = false;
    v$.value.$reset();
  };

  const toggleShowPassword = () => {
    showPassword.value = !showPassword.value;
  };

  return {
    userEmail,
    password,
    showPassword,
    submittedWithErrors,
    submitting,
    toggleShowPassword,
    v$,
    submit
  };
}
