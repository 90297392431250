<template>
  <v-app class="app">
    <div class="d-flex flex-column justify-center  auth-container">
      <div class="d-flex flex-column align-center justify-center">
        <div>
          <img src="@/assets/logo.png" width="238" />
        </div>
        <br />
        <v-card class="page-card" max-width="400">
          <div class="d-flex flex-column">
            <p class="page-title text-center">Inicia sesión en tu cuenta</p>
            <LoginByGoogle
              @onError="onError"
            ></LoginByGoogle>
            <div class="mb-4"></div>
            <LoginByFacebook
              @onError="onError"
            ></LoginByFacebook>
            <p class="or-text mt-3 mb-3 text-center">O BIEN</p>
            <LoginByEmail
              @onError="onError"
            ></LoginByEmail>
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <div class="auth-actions">
            <ul>
              <li>
                <router-link to="/login/resetpassword" class="no-decoration">
                  <span>¿No puedes iniciar sesión?</span>
                </router-link>
              </li>
              <li>
                <router-link to="/signup" class="no-decoration">
                  <span>Registrarse para obtener nueva cuenta</span>
                </router-link>
              </li>
            </ul>
          </div>
        </v-card>
        <br />
        <PrivacyFooter></PrivacyFooter>
      </div>
    </div>
  </v-app>
</template>

<script>
import LoginByEmail from "../components/LoginByEmail.vue";
import LoginByGoogle from "../components/LoginByGoogle.vue";
import LoginByFacebook from "../components/LoginByFacebook.vue";
import PrivacyFooter from "../components/PrivacyFooter.vue";

export default {
  components: {
    LoginByEmail,
    LoginByGoogle,
    LoginByFacebook,
    PrivacyFooter
  },
  setup() {
    const onError = function(error) {
      this.$notify(error, "error");
    };

    return {
      onError
    };
  }
};
</script>

<style type="scss">
.app {
  background-color: #fafbfc !important;
}

.auth-container {
  height: 100vh;
}

.page-card {
  padding: 32px 40px;
}

.or-text {
  color: rgb(151, 160, 175);
  display: block;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}

.auth-actions {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.auth-actions ul {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0 !important;
}
</style>
