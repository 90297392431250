export default {
  createCustomTokenUrl:
    "https://europe-west1-goveoappdev.cloudfunctions.net/app/auth/createCustomToken",
  firebaseConfig: {
    apiKey: "AIzaSyB0uQjPhResuPnCXLnV-va3ZVVSS2Xcxx0",
    authDomain: "goveoappdev.firebaseapp.com",
    databaseURL: "https://goveoappdev.firebaseio.com",
    projectId: "goveoappdev",
    storageBucket: "goveoappdev.appspot.com",
    messagingSenderId: "443604029831",
    appId: "1:443604029831:web:97cdced1451fc7c8130322"
  }
};
