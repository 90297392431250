import { ref } from "@vue/composition-api";

export function useTermsDialog() {
  const termsDialog = ref(false);

  const openTerms = () => {
    termsDialog.value = true;
  };

  const closeTerms = () => {
    termsDialog.value = false;
  };

  return {
    termsDialog,
    openTerms,
    closeTerms,
  };
}
