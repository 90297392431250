<template>
  <v-app class="app">
    <div class="d-flex flex-column justify-center  auth-container">
      <div class="d-flex flex-column align-center justify-center">
        <div>
          <img src="@/assets/logo.png" width="238" />
        </div>
        <br />
        <v-card class="page-card" max-width="400">
          <div class="d-flex flex-column">
            <ResetPassword
              @onError="onError"
              @onSuccess="onSuccess"
            ></ResetPassword>
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <div class="auth-actions">
            <ul>
              <li>
                <router-link to="/login" class="no-decoration">
                  <span>Volver a inicio de sesión</span>
                </router-link>
              </li>
            </ul>
          </div>
        </v-card>
        <br />
        <div class="auth-actions">
          <ul>
            <li>
              <a href="mailto:hola@goveo.app" class="no-decoration">
                <span>Ponerse en contacto con el soporte</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ResetPassword from "../components/ResetPassword.vue";

export default {
  components: {
    ResetPassword
  },
  setup() {
    const onError = function(error) {
      this.$notify(error, "error");
    };

    const onSuccess = function(message) {
      this.$notify(message, "success");
    };

    return {
      onError,
      onSuccess
    };
  }
};
</script>

<style type="scss">
.app {
  background-color: #fafbfc !important;
}

.auth-container {
  height: 100vh;
}

.page-card {
  padding: 32px 40px;
}

.auth-actions {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.auth-actions ul {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0 !important;
}
</style>
